import { ChangeEvent, FormEvent, useEffect, useRef } from "react";
import PageWrapper from "components/utility/PageWrapper";
import PanelInputField from "components/InfoPanel/PanelInputField";
import classes from "./InfoPanel.module.css";
import { useState } from "react";
import TermsModal from "components/Login/TermsModal";
import { useLogin } from "hooks/useLogin";
import { useParams } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import { checkPasswordError } from "../../../utility/inputValidation";

// import { useAuthContext } from "../../../hooks/useAuthContext";

import { customFilter } from "components/Home/Community/Profanity/ProfanityFilter";

import { useGrowthCircleContext } from "hooks/useGrowthCircleContext";
import NGFooter from "components/Footers/NGFooter";
import toast, { Toaster } from "react-hot-toast";
import { useDocument } from "hooks/useDocument";
import useInputHandler from "hooks/form/useInputHandler";
import EmptySpace from "components/utility/EmptySpace";
import { Checkbox, Modal } from "flowbite-react";
import { IoMdCloseCircle } from "react-icons/io";
import SimpleLoader from "components/Loaders/SimpleLoader";
import Organisation, {
  OrganisationDropdownItem,
} from "interface/OrganisationInterface";

import { getOrgByName } from "models/organisation";
import PanelSelectField from "components/InfoPanel/PanelSelectField";
import TickedCheckbox from "assets/TickedCheckbox";
import PasswordChecker from "pages/Login/PasswordChecker";
import { Link } from "react-router-dom";

/**
 * Page that allows users to quickly create a guest account to join a GCSession
 */
const InfoPanel = () => {
  const { value: displayName, onChange: onChangeDisplayName } =
    useInputHandler("");
  const { value: email, onChange: onChangeEmail } = useInputHandler("");
  const [password, setPassword] = useState("");
  const [confirm_password, setConfirmPassword] = useState("");
  const { value: personalID, onChange: onChangePersonalID } =
    useInputHandler("");
  const { InfoPanelLogin, isPending, error, login, loginGoogle } = useLogin();

  const { growthCircleSession } = useGrowthCircleContext();
  const { id } = useParams();
  const [usernameError, setUsernameError] = useState<string | null>(null);

  const { document } = useDocument("GrowthCircles", id);
  const [isVisible, setIsVisible] = useState(false);
  const [verified, setVerified] = useState(false);
  const recaptchaRef = useRef();
  const [isStrong, setIsStrong] = useState(false);

  const [organisationObject, setOrganisationObject] = useState<Organisation>();

  const [selectedValues, setSelectedValues] = useState<{
    [label: string]: string;
  }>({});

  const [textareaValues, setTextareaValues] = useState<{
    [label: string]: string;
  }>({});

  const handleChange = (e: string) => {
    const newPassword: string = e;
    setPassword(newPassword);

    // Set the state based on the criteria
    setIsStrong(checkPasswordError(newPassword));

    // Hide terms if there is password is not strong (especially if the password set was initially strong)
    if (!checkPasswordError(newPassword)) {
      setShowTerms(false);
      setTermsAccepted(false);
    }
  };

  const setConfirmPasswordHandler = (e: string) => {
    setConfirmPassword(e);
    handleChange(password);
    // Check if the password matches the confirmation
    const matches = password === e;
    setIsStrong(matches); // Update isStrong based on matches

    // Hide terms if there is no match
    if (!matches) {
      setShowTerms(false);
      setTermsAccepted(false);
    }
  };

  const handleDropdownChange = (label: string, value: string) => {
    const formattedLabel = label.replace(/ /g, "_");
    // console.log(`Selected ${label}: ${value}`);

    setSelectedValues({
      ...selectedValues,
      [formattedLabel]: value,
    });

    // If "Others" is selected in the dropdown, clear the textarea value
    if (value === "Others") {
      setTextareaValues({
        ...textareaValues,
        [formattedLabel]: "",
      });
    }
  };

  const handleTextareaChange = (label: string, value: string) => {
    const formattedLabel = label.replace(/ /g, "_");
    // console.log(`Textarea for ${label}: ${value}`);

    setTextareaValues({
      ...textareaValues,
      [formattedLabel]: value,
    });
  };

  const OrgID = organisationObject?.id;

  // Initialize an empty object to store the collected data with a dynamic key
  const collectedDataObject: {
    [key: string]: { label: string; value: string; otherValues?: string }[];
  } = {
    [OrgID || "default"]: [], // Use OrgID as the key, or a default key if OrgID is falsy
  };

  // Collect the data and populate the object
  Object.keys(selectedValues).forEach((label) => {
    const originalLabel = label.replace(/_/g, " "); // Revert back to original label format

    collectedDataObject[OrgID || "default"].push({
      label: originalLabel,
      value: selectedValues[label],
      otherValues: textareaValues[label] ?? "",
    });
  });

  var profanityFilter = customFilter;
  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();

    if (password === confirm_password) {
      if (profanityFilter.isProfane(displayName)) {
        setUsernameError("Profanities are not allowed");
      } else {
        setUsernameError(null);

        InfoPanelLogin(
          email,
          displayName,
          id,
          password,
          personalID,
          "",
          collectedDataObject
        );
      }
    } else {
      toast.error("Passwords do not match");
    }
  };

  const ModalLoginHandler = async (e: FormEvent) => {
    e.preventDefault();
    login(email, password, id);
  };

  const googleHandler = () => {
    loginGoogle(id);
  };

  //States
  const [showTerms, setShowTerms] = useState(false);

  const [avatarHashMap, setAvatarHashMap] = useState({});
  const [termsAccepted, setTermsAccepted] = useState(false);
  const showTermsHandler = () => {
    setShowTerms(true);
  };

  const hideTermsHandler = () => {
    setShowTerms(false);
  };

  const checkboxHandler = () => {
    if (termsAccepted === true) {
      setTermsAccepted(false);
    } else {
      setTermsAccepted(true);
    }
  };

  useEffect(() => {
    if (!document) return;
    getOrgByName(document.organisation).then((doc) => {
      if (doc) {
        if (!organisationObject) setOrganisationObject(doc);
      }
    });

    // eslint-disable-next-line
  }, [id, organisationObject, document]);

  useEffect(() => {
    if (!organisationObject?.uniqueFields) return;
    const updatedSelectedValues = { ...selectedValues };
    const updatedTextareaValues = { ...textareaValues };
    organisationObject.uniqueFields.forEach((data) => {
      const formattedLabel = data.label.replace(/ /g, "_");

      // Update the selected value
      updatedSelectedValues[formattedLabel] = data.options[0];
      setSelectedValues(updatedSelectedValues);
      setTextareaValues(updatedTextareaValues);
    });
    // eslint-disable-next-line
  }, [organisationObject, document]);

  //Initialization
  useEffect(() => {
    setAvatarHashMap({
      ...avatarHashMap,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (error) {
      var cleanErrorMessage = error.split("Firebase: ")[1]; // This will remove 'Firebase: ' and everything before it.
      if (cleanErrorMessage)
        toast.error(cleanErrorMessage, {
          position: "bottom-center",
          duration: 6000,
        });
    }
  }, [error]);

  const handleRecaptcha = (response) => {
    verifyRecaptcha(response);
  };
  const verifyRecaptcha = (response: string) => {
    const url =
      "https://us-central1-nobeans-110a4.cloudfunctions.net/verifyRecaptcha";
    const params = new URLSearchParams();

    params.append("response", response);

    return fetch(url, {
      method: "POST",
      body: params,
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
    })
      .then((response) => response.json())
      .then((data) => {
        // console.log(data);
        if (data.success) {
          setVerified(true);
        } else {
          setVerified(false);
          toast.error("failed captcha please try again");
        }
        // handle the response data from the reCAPTCHA verification
      })
      .catch((error) => {
        console.error(error);
        // handle any errors that occurred during the request
      });
  };

  return (
    <PageWrapper pageType={"welcome"}>
      <Modal
        show={isVisible}
        onClose={() => setIsVisible(!isVisible)}
        className="min-h-screen"
      >
        <Modal.Body className="flex justify-center items-center relative bg-main-colour rounded-lg py-16">
          {!isPending ? (
            <>
              {" "}
              <IoMdCloseCircle
                onClick={() => setIsVisible(!isVisible)}
                size={30}
                className="cursor-pointer text-default absolute top-2 right-2  dark:text-gray-200"
              />{" "}
              <form onSubmit={ModalLoginHandler}>
                <div className="space-y-2">
                  <PanelInputField
                    id={"Email"}
                    label={"Email"}
                    type={"email"}
                    placeholder={"Your email here"}
                    value={{
                      value: email,
                      onChange: onChangeEmail,
                    }}
                    Invalid={false}
                  />

                  <PanelInputField
                    id={"Password"}
                    label={"Password"}
                    type={"password"}
                    placeholder={"•••••••••••"}
                    value={{
                      value: password,
                      onChange: (e: ChangeEvent<HTMLInputElement>) =>
                        handleChange(e.target.value),
                    }}
                    Invalid={false}
                  />

                  <div>
                    <button
                      disabled={isPending ? true : false}
                      className={classes["submitButton"]}
                      type="submit"
                    >
                      {isPending ? "Loading.." : "Login"}
                    </button>
                  </div>
                  <div className="text-center py-4">
                    <span className="text-default">Or </span>
                    <div
                      className="bg-default shadow-md rounded-full my-4 w-full flex items-center justify-center py-4 cursor-pointer"
                      onClick={googleHandler}
                    >
                      <svg
                        fill="none"
                        height="32"
                        viewBox="0 0 24 24"
                        width="32"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="m21.8055 10.0415h-.8055v-.0415h-9v4h5.6515c-.8245 2.3285-3.04 4-5.6515 4-3.3135 0-6-2.6865-6-6s2.6865-6 6-6c1.5295 0 2.921.577 3.9805 1.5195l2.8285-2.8285c-1.786-1.6645-4.175-2.691-6.809-2.691-5.5225 0-10 4.4775-10 10s4.4775 10 10 10 10-4.4775 10-10c0-.6705-.069-1.325-.1945-1.9585z"
                          fill="#ffc107"
                        ></path>
                        <path
                          d="m3.15295 7.3455 3.2855 2.4095c.889-2.201 3.042-3.755 5.56155-3.755 1.5295 0 2.921.577 3.9805 1.5195l2.8285-2.8285c-1.786-1.6645-4.175-2.691-6.809-2.691-3.84105 0-7.17205 2.1685-8.84705 5.3455z"
                          fill="#ff3d00"
                        ></path>
                        <path
                          d="m12 22c2.583 0 4.93-.9885 6.7045-2.596l-3.095-2.619c-1.004.7605-2.252 1.215-3.6095 1.215-2.60097 0-4.80947-1.6585-5.64147-3.973l-3.261 2.5125c1.655 3.2385 5.016 5.4605 8.90247 5.4605z"
                          fill="#4caf50"
                        ></path>
                        <path
                          d="m21.8055 10.0415h-.8055v-.0415h-9v4h5.6515c-.396 1.1185-1.1155 2.083-2.0435 2.7855.0005-.0005.001-.0005.0015-.001l3.095 2.619c-.219.199 3.2955-2.4035 3.2955-7.4035 0-.6705-.069-1.325-.1945-1.9585z"
                          fill="#1976d2"
                        ></path>
                      </svg>{" "}
                      <p className="text-xl text-slate-500">Google</p>
                    </div>
                  </div>
                </div>
              </form>
            </>
          ) : (
            <div className="flex flex-col justify-center items-center h-96">
              <p className="my-4 text-default">Please wait...</p>
              <SimpleLoader />
            </div>
          )}
        </Modal.Body>
      </Modal>
      <TermsModal
        show={showTerms}
        onClick={hideTermsHandler}
        onAccept={checkboxHandler}
      />
      <Toaster toastOptions={{ duration: 5000 }} />
      {document && (
        <div
          className={`${classes["container"]} flex justify-center items-center h-[80vh] max-w-[400px] w-full relative mb-[100px]`}
        >
          {/* Modal */}

          <form onSubmit={handleSubmit} className="py-8">
            {/* AvatarBubble */}

            {/* Carousel */}

            {/* InputFields */}
            <PanelInputField
              id={"Name"}
              label={"How shall I address you?"}
              type={"text"}
              placeholder={"Your name here"}
              value={{
                value: displayName,
                onChange: onChangeDisplayName,
              }}
              Invalid={usernameError}
            />
            <PanelInputField
              id={"Email"}
              label={"Email"}
              type={"email"}
              placeholder={"Your email here"}
              value={{
                value: email,
                onChange: onChangeEmail,
              }}
              Invalid={false}
            />

            <PanelInputField
              id={"Password"}
              label={"Password"}
              type={"password"}
              placeholder={"•••••••••••"}
              value={{
                value: password,
                onChange: (e: ChangeEvent<HTMLInputElement>) =>
                  handleChange(e.target.value),
              }}
              Invalid={false}
            />
            <PanelInputField
              id={"Confirm_Password"}
              label={"Confirm Password"}
              type={"password"}
              placeholder={"•••••••••••"}
              value={{
                value: confirm_password,
                onChange: (e: ChangeEvent<HTMLInputElement>) =>
                  setConfirmPasswordHandler(e.target.value),
              }}
              Invalid={false}
            />
            <PasswordChecker
              password={password}
              confirm_password={confirm_password}
              theme="dark"
            />
            {id && organisationObject?.hasUniqueFields && (
              <>
                {organisationObject.uniqueFields &&
                  organisationObject.uniqueFields.map(
                    (item: OrganisationDropdownItem, index) =>
                      item.field_type === "dropdown" ? (
                        <div key={index} className="w-full">
                          <div className="py-2">
                            <PanelSelectField
                              label={item.label}
                              handleChange={(e) =>
                                handleDropdownChange(item.label, e.target.value)
                              }
                              options={item.options}
                            />
                          </div>

                          {selectedValues[item.label.replace(/ /g, "_")] ===
                            "Others" && (
                            <div>
                              <textarea
                                rows={4}
                                className="w-full my-4 text-slate-500 bg-default border-none rounded-lg"
                                required
                                value={
                                  textareaValues[
                                    item.label.replace(/ /g, "_")
                                  ] || ""
                                }
                                onChange={(e) =>
                                  handleTextareaChange(
                                    item.label,
                                    e.target.value
                                  )
                                }
                                placeholder={`Others ${item.label}`}
                              />
                            </div>
                          )}
                        </div>
                      ) : null
                  )}
              </>
            )}

            {document &&
              document.type !== undefined &&
              document.type === "SUSS" && (
                <>
                  {" "}
                  <PanelInputField
                    id={"pid"}
                    label={"SUSS Personal Identification No."}
                    type={"text"}
                    placeholder={"Your ID number here"}
                    value={{
                      value: personalID,
                      onChange: onChangePersonalID,
                    }}
                    Invalid={false}
                  />
                </>
              )}

            {isStrong &&
              password === confirm_password &&
              displayName !== "" &&
              email !== "" && (
                <div>
                  {/* Terms and Conditions */}

                  <div className="flex justify-start">
                    <div className={classes.checkbox}>
                      {termsAccepted ? (
                        <TickedCheckbox onClick={checkboxHandler} />
                      ) : (
                        <Checkbox onClick={checkboxHandler} />
                      )}
                    </div>
                    <label className={`${classes["termsLabel"]} px-2`}>
                      <span className="w-1/2 text-center px-2">
                        You agree to the terms and conditions in the {""}
                        <button
                          type="button"
                          className="text-yellow-200"
                          onClick={showTermsHandler}
                        >
                          privacy notice.
                        </button>
                      </span>
                    </label>
                  </div>

                  <div className="my-4">
                    <ReCAPTCHA
                      ref={recaptchaRef}
                      sitekey="6Ld0jxInAAAAACy3275Ql2jd_Bb5YDqZWrSnaBdx"
                      onChange={handleRecaptcha}
                      size="normal"
                      theme="light"
                    />
                  </div>

                  {/* Submit */}
                  {verified && termsAccepted && (
                    <button className={classes["submitButton"]} type="submit">
                      {isPending ? "Loading.." : "Next"}
                    </button>
                  )}
                </div>
              )}
          </form>
          <EmptySpace height="10px" />
          <div
            className="text-center cursor-pointer"
            onClick={() => setIsVisible(!isVisible)}
          >
            <p className="text-default">Already have an account?</p>
            <p className="text-default">
              click <span className="font-semibold underline">here</span> to
              login
            </p>
          </div>

          <div className="text-center cursor-pointer mt-4">
            <p className="text-default">Forgot password?</p>
            <p className="text-default">
              click{" "}
              <Link to="/forget-password" className="font-semibold underline">
                here
              </Link>
            </p>
          </div>

          {growthCircleSession?.type === "NG" && <NGFooter />}
        </div>
      )}
    </PageWrapper>
  );
};

export default InfoPanel;
